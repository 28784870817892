<template>
  <div class="center_box">
    <div class="top">
      <img src="@/assets/img/verification/验证成功.png" alt="" />
      <span>验证成功</span>
    </div>

    <div class="centerResult">
      <!-- 存证信息 -->
      <div class="infoBox">
        <p class="title">存证信息</p>
        <ul class="detailInfo">
          <li>
            <span>存证编号：</span>
            <span class="infoText">{{ successData.noid }}</span>
          </li>
          <li>
            <span>存证名称：</span>
            <span class="infoText">{{ successData.name }}</span>
          </li>
          <li>
            <span>存证类型：</span>
            <span class="infoText">{{
              successData.txType | txTypeToText
            }}</span>
          </li>
          <li>
            <span>存证主体：</span>
            <span class="infoText">{{ successData.authName }}</span>
          </li>
          <li>
            <span>内容类型：</span>
            <span class="infoText">{{ successData.type | typeToText }}</span>
          </li>
        </ul>
      </div>
      <!-- 区块信息 -->
      <div class="infoBox">
        <p class="title">区块链信息</p>
        <ul class="detailInfo">
          <li>
            <span>区块链存证ID：</span>
            <span class="infoText">{{ successData.txhash }}</span>
          </li>
          <li>
            <span>数据指纹hash：</span>
            <span class="infoText">{{ successData.filehash }}</span>
          </li>
          <li>
            <span>存证状态：</span>
            <span
              class="infoText"
              :class="{ successText: successData.status === 1 }"
              >{{ successData.status | statusToText }}</span
            >
          </li>
          <li>
            <span>区块高度：</span>
            <span class="infoText">{{ successData.blockHeight }}</span>
          </li>
          <li>
            <span>存证时间：</span>
            <span class="infoText">{{ successData.created | dateForMat }}</span>
          </li>
        </ul>
      </div>

      <!-- 内容信息 -->
      <div class="infoBox">
        <p class="title">内容信息</p>
        <div class="detailInfo" v-if="successData.type === 'IMAGE'">
          <el-image
            class="contentImage"
            :src="`${baseUrl}${successData.FileInfo.url}`"
            :preview-src-list="[baseUrl + successData.FileInfo.url]"
            :initial-index="1"
          >
          </el-image>
        </div>
        <div class="detailInfo" v-if="successData.type === 'TEXT'">
          {{ successData.content }}
        </div>
        <div
          class="detailInfo notPreview"
          v-if="successData.type !== 'TEXT' && successData.type !== 'IMAGE'"
        >
          <img src="@/assets/img/verification/no_see.svg" alt="" />
          <div class="desctription">文件预览/下载</div>
          <button class="downloadFile" @click="downloadFile">
            文件下载
            <img
              src="../../../assets/img/verification/downloadIcon.svg"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="backToVerify" class="btnSub"
        >返回重新验证</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      baseUrl: "http://183.3.158.22:8598", //测试服
      // baseUrl: "https://upload.scaniov.com", //正式服
    };
  },
  props: {
    successData: {
      type: Object,
    },
  },
  methods: {
    // 返回验证
    backToVerify() {
      this.$emit("comChange", { comName: "ShareQuery" });
    },
    downloadFile() {
      let imgType = [".png", ".jpg", ".jpeg", ".gif", ".webp", ".svg"];
      let dataFormat = this.successData.FileInfo.format;
      let dataURL = `${this.baseUrl}/${this.successData.FileInfo.url}`;
      let name = this.successData.name;
      if (imgType.includes(dataFormat)) {
        this.downloadByBlob(dataURL, name);
      } else if (dataFormat === ".txt") {
        let href = URL.createObjectURL(new Blob([this.successData.content]));
        this.download(href, name);
      } else {
        this.download(dataURL, name);
      }
    },
    downloadByBlob(url, name) {
      let image = new Image();
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
      image.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, image.width, image.height);
        canvas.toBlob((blob) => {
          let url = URL.createObjectURL(blob);
          this.download(url, name);
          // 用完释放URL对象
          URL.revokeObjectURL(url);
        });
      };
    },
    download(href, name) {
      // 必须同源（访问的网站域名与服务器域名一致）才能下载
      let eleLink = document.createElement("a");
      eleLink.href = href; // 转换后的图片地址
      eleLink.download = name;
      document.body.appendChild(eleLink);
      // 触发点击
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    },
  },
  mounted() {},
  filters: {
    // 时间格式转换
    dateForMat: function (time) {
      // 补零
      const addZero = function (sum) {
        return sum > 9 ? sum : "0" + sum;
      };
      let date = new Date(time * 1000);
      let YY = date.getFullYear();
      let M = date.getMonth() + 1;
      let DD = date.getDate();
      let HH = date.getHours();
      let MM = date.getMinutes();
      let SS = date.getSeconds();
      return `${YY}/${M}/${DD} ${addZero(HH)}:${addZero(MM)}:${addZero(SS)}`;
    },
    typeToText(val) {
      let text;
      switch (val) {
        case "TEXT":
          text = "文本类型";
          break;
        case "IMAGE":
          text = "图片";
          break;
        case "VOICE":
          text = "音频";
          break;
        case "VIDEO":
          text = "视频";
          break;
        case "FILE":
          text = "文件";
          break;
      }
      return text;
    },
    txTypeToText(val) {
      let text;
      switch (val) {
        case 1:
          text = "普通存证";
          break;
        case 2:
          text = "司法存证";
          break;
        case 3:
          text = "版权存证";
          break;
      }
      return text;
    },
    statusToText(val) {
      let text;
      switch (val) {
        case 0:
          text = "未存证";
          break;
        case 1:
          text = "成功";
          break;
        case 2:
          text = "存证失败";
          break;
        case 3:
          text = "存证中";
          break;
      }
      return text;
    },
  },
};
</script>

<style lang="less" scoped>
.center_box {
  display: block;
  margin-top: 50px;
  .miniScreen {
    display: none;
  }
  .btnSub {
    margin-left: 0;
  }
  .top {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .bottom {
    display: flex;
    height: 80px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .btnSub {
      display: flex;
      justify-content: center;
    }
  }
}

.centerResult {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  .infoBox {
    margin: 20px 0;
    .title {
      line-height: 30px;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 14px;
        background-color: #387efd;
        margin-right: 5px;
      }
    }
    .detailInfo {
      border: 1px solid #ececec;
      background-color: #fcfcfc;
      padding: 15px;
      border-radius: 5px;

      > li {
        line-height: 30px;

        span {
          color: #666;
          display: inline-flex;
        }
        .infoText {
          color: #333;
          line-height: 30px;
          word-break: break-all;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        .successText {
          color: #2ba245;
        }
      }

      .contentImage {
        display: inline-block;
        width: 100%;
        object-fit: contain;
      }
    }

    .notPreview {
      display: flex;
      flex-direction: column;
      align-items: center;
      .desctription {
        text-align: center;
        margin: 15px 0;
      }
      .downloadFile {
        border: 1px solid #dcdcdc;
        border-radius: 4px;
        padding: 10px 15px;
        &:hover {
          background-color: #e0e0e0;
          // border: 1px solid #387efd;
          // color: #387efd;
        }
      }
    }
  }
}

@media screen and(max-width:520px) {
  .center_box {
    margin-top: 10px;
    .top {
      margin: 10px 0;
    }
    .bottom {
      height: 100%;
      .btnSub {
        margin-top: 10px;
      }
      table {
        display: none;
      }
      .miniScreen {
        display: block;
        > li {
          padding: 0 20px;
          line-height: 30px;
          span {
            color: #666;
          }
          .spanContent {
            display: -webkit-box;
            display: inline-block;
            word-break: break-all;
            -webkit-box-orient: vertical;
            overflow: hidden;
            width: 15.625rem;
            color: #333;
          }
        }
      }
    }
  }
  .centerResult {
    width: 90%;
    .infoBox {
      .title {
        margin-bottom: 5px;
      }
    }
  }
}
</style>